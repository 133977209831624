import { Link } from "gatsby"
import React from "react"
import { FaInstagram, FaLinkedinIn } from "react-icons/fa"
import { infoFooter } from "../../mock/data"

interface Props {
  blog?: boolean
}

const Footer = ({ blog }: Props) => {
  const concat = `mailto:${infoFooter.email}`
  return (
    <section
      className={`flex items-center justify-center ${blog ? "bg-blackPearl" : ""} `}
      id="footer"
    >
      <div className="text-white font-barlow flex flex-col justify-center items-center mt-12">
        <div className="flex">
          <a
            className="text-cyan"
            href="https://www.instagram.com/enesolucoes/?hl=pt-br"
            target="_blank"
            rel="noreferrer noopener"
          >
            <FaInstagram className="" size={24} />
          </a>
          <a
            className="text-cyan pl-7"
            href="https://www.linkedin.com/company/enesolucoes/"
            target="_blank"
            rel="noreferrer noopener"
          >
            <FaLinkedinIn className="" size={24} />
          </a>
        </div>
        <a
          href={infoFooter.linkAddres}
          target="_blank"
          className="text-white my-4 font-bold text-center sm:text-xs md:text-base lg:text-base xl:text-base"
          rel="noreferrer noopener"
        >
          {infoFooter.address}
        </a>
        <div className="text-center">
          <a
            href="https://api.whatsapp.com/send?phone=553432295811"
            target="_blank"
            className="text-white underline font-bold sm:text-xs md:text-base lg:text-base xl:text-base"
            rel="noreferrer noopener"
          >
            {infoFooter.number}
          </a>
          <span className="text-cyan font-bold mx-2.5"> | </span>
          <a
            className="text-white font-bold sm:text-xs md:text-base lg:text-base xl:text-base"
            href={concat}
          >
            {infoFooter.email}
          </a>
        </div>
        <div className="flex underline sm:text-xs md:text-base lg:text-base xl:text-base my-4">
          <Link className="pr-7 text-cyan font-bold" to="/termos-de-uso">
            Termos de uso
          </Link>
          <Link
            className="pr-7 text-cyan font-bold"
            to="/politicas-de-privacidade"
          >
            Políticas de privacidade
          </Link>
          <Link className="text-cyan font-bold" to="/dpc">
            DPC
          </Link>
        </div>
        <Link to="/">
          <img width="100%" height="100%" src={infoFooter.logo} alt="eNe Soluções" className="mt-16" />
        </Link>
        <p className="font-light my-4 text-center sm:text-xs md:text-base lg:text-base xl:text-base">
          {infoFooter.copyright}
        </p>
      </div>
    </section>
  )
}

export default Footer
