import _cloneDeep from "lodash/cloneDeep"
import React, { useEffect, useState } from "react"
import { Collapse } from "react-collapse"
import { GiHamburgerMenu } from "react-icons/gi"
import { MdClose } from "react-icons/md"
import logo from "../../images/logo.svg"
import { infoItemsMenu } from "../../mock/data"
import ItemsMenu from "./liMenu"
import * as styles from "./styles.module.css"

import cx from "classnames"

interface Props {
  pageName?:
  | "design-de-solucoes"
  | "qualidade-de-software"
  | "dpc"
  | "politicas-de-privacidade"
  | "termos-de-uso"
  activeMenu?: string
  blog?: boolean
}

const Navbar = ({ pageName, activeMenu, blog }: Props): JSX.Element => {
  const [navbarOpen, setNavbarOpen] = useState(false)
  const [innerHeight, setInnerHeight] = useState(Number)

  useEffect(() => {
    if (typeof window !== "undefined") {
      setInnerHeight(window.innerHeight * -1)
    }
  }, [])

  useEffect(() => {
    if (navbarOpen) {
      document.body.style.overflow = "hidden"
      return
    }
    document.body.style.overflow = "auto"
  }, [navbarOpen])

  const gridClasses = cx("m-auto main-container")
  const heightMenuClasses = cx("m-0 bg-elephant sm:w-full lg:hidden", {
    "sm:h-screen": navbarOpen,
  })

  const currentMenu = pageName
    ? infoItemsMenu[pageName]
    : infoItemsMenu.menuHome

  const cloneMenuItems = _cloneDeep(currentMenu)


  return (
    <>
      <nav className={gridClasses}>
        <div className="flex flex-col lg:flex-row ">
          <div className="sm:pr-0 md:pr-0 lg:w-full flex justify-between lg:static lg:block ">
            <a
              className="text-sm sm:pr-10 md:pr-0 font-bold leading-relaxed inline-block my-6 whitespace-nowrap uppercase text-white"
              href="/"
            >
              <img
                className="sm:flex md:hidden"
                src={logo}
                alt="Logo Ene Soluções"
                width={167}
              />
              <img
                className="sm:hidden md:flex lg:hidden"
                src={logo}
                alt="Logo Ene Soluções"
                width={220}
              />
              <img
                className="sm:hidden lg:flex"
                src={logo}
                alt="Logo Ene Soluções"
                width={267}
              />
            </a>
            <button
              className="text-cyan cursor-pointer text-xl leading-none py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
              type="button"
              onClick={() => setNavbarOpen(!navbarOpen)}
            >
              {navbarOpen ? (
                <MdClose size={24} />
              ) : (
                <GiHamburgerMenu size={24} />
              )}
            </button>
          </div>
          <div className={"sm:hidden md:hidden lg:flex flex-grow items-center"}>
            <ul className="m-0 lg:static bg-elephant lg:h-auto lg:w-auto lg:static ">
              <div className="flex flex-col w-max lg:flex-row lg:items-center list-none lg:ml-auto">
                <ItemsMenu
                  menuItems={blog ? cloneMenuItems : currentMenu}
                  setNavbarOpen={setNavbarOpen}
                  innerHeight={innerHeight}
                  activeMenu={activeMenu}
                />
              </div>
            </ul>
          </div>
        </div>
        <Collapse
          isOpened={navbarOpen}
          theme={{ collapse: styles.ReactCollapse }}
        >
          <ul className={heightMenuClasses}>
            <div className="flex flex-col w-max sm:items-start md:items-start list-none">
              <ItemsMenu
                menuItems={blog ? cloneMenuItems : currentMenu}
                setNavbarOpen={setNavbarOpen}
                isMobile
                innerHeight={innerHeight}
                activeMenu={activeMenu}
              />
            </div>
          </ul>
        </Collapse>
      </nav>
    </>
  )
}
export default Navbar
