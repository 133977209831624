import { navigate } from "gatsby"
import imgTesteAcessibilidade from "../images/acessibilidade.png"
import designStepByStepFile from "../images/design-step-by-step-file.png"
import designStepByStepSchedule from "../images/design-step-by-step-schedule.png"
import designStepByStepTalk from "../images/design-step-by-step-talk.png"
import eneAcademyTimeline from "../images/eneAcademyTimeline.png"
import logoWhite from "../images/logoWhite.png"
import recommendationImgCard from "../images/lp-recommendation-image-card.png"
import companyLogoDesktop from "../images/lp-recommendation-logo-client-desktop.png"
import companyLogoMobile from "../images/lp-recommendation-logo-client-mobile.png"
import companyLogoTablet from "../images/lp-recommendation-logo-client-tablet.png"
import imgTesteResponsivo from "../images/responsivo.png"
import imgTesteAutomatizado from "../images/teste-automatizado.png"
import imgTesteFuncionalidade from "../images/teste-funcionalidade.png"
import imgTesteUsabilidade from "../images/usabilidade.png"

// Cards Squad - Icons
import squadIconMVP from "../images/icon_acessibilidade.svg"
import squadIconAsService from "../images/icon_front_e_back.svg"
import squadIconImmersion from "../images/icon_fullstack.svg"
import squadIconSobMedida from "../images/icon_sob_medida.svg"
import squadIconTeamLead from "../images/icon_teamlead.svg"
import squadIconProjectMeasure from "../images/icon_ui.svg"

//timeline QA
import qaTimelineApresentation from "../images/QA-apresentacao.png"
import qaTimelineSpecialistConsultation from "../images/QA-consulta-especialista-.png"
import qaTimelineMacroRoadmap from "../images/QA-elaboracao-do-roteiro-macro.png"
import qaTimelineRetest from "../images/QA-re-teste.png"
import qaTimelineProblemsEncountered from "../images/QA-relatorios-d-problemas-encontrados.png"
import qaTimelineTest from "../images/QA-teste.png"
//timeline design
import designVisualConcept from "../images/Design-conceitoVisual.png"
import designExpertConsultation from "../images/Design-consulta-especialista.png"
import designProjectionForTheDevelopment from "../images/Design-projecao-para-o-desenvolvimento.png"
import designSailablePrototype from "../images/Design-prototipo-navegavel.png"
import designWireframe from "../images/Design-wireframe.png"

//   BADGES
export const badgesDigitalSolutions = [
  {
    icon: "&#x1F469;&#x200D;&#x1F4BB;",
    text: "Dev",
  },
  {
    text: "Design",
  },
  {
    text: "Consult",
  },
]
export const designTimelineBadges = [
  {
    icon: "&#x1F469;&#x200D;&#x1F4BB;",
    text: "Passo a passo",
  },
  {
    text: "Benefits",
  },
  {
    text: "Process",
  },
]
export const qaTimelineBadges = [
  {
    icon: "&#x1F469;&#x200D;&#x1F4BB;",
    text: "Passo a passo",
  },
  {
    text: "Benefits",
  },
  {
    text: "Process",
  },
]
export const badgesTechnologies = [
  {
    icon: "&#x1F469;&#x200D;&#x1F4BB;",
    text: "Tecnologias",
  },
  {
    text: "Coding",
  },
  {
    text: "Process",
  },
]
export const badgesReferences = [
  {
    icon: "&#x1F469;&#x200D;&#x1F4BB;",
    text: "Referências",
  },
  {
    text: "Clients",
  },
  {
    text: "Projects",
  },
]
export const badgesSquadAllocation = [
  {
    icon: "&#x1F469;&#x200D;&#x1F4BB;",
    text: "Alocação",
  },
  {
    text: "Outsourcing",
  },
  {
    text: "Team",
  },
]
export const badgesForm = [
  {
    icon: "&#128242;",
    text: "Contato",
  },
  {
    text: "Business",
  },
  {
    text: "Meeting",
  },
]
export const badgesDPC = [
  {
    icon: "&#x1F469;&#x200D;&#x1F4BB;",
    text: "tag",
  },
  {
    text: "tag",
  },
  {
    text: "tag",
  },
]
export const badgesPrivacyPolicies = [
  {
    icon: "&#x1F469;&#x200D;&#x1F4BB;",
    text: "tag",
  },
  {
    text: "tag",
  },
  {
    text: "tag",
  },
]

export const designBadgesStepByStep = [
  {
    icon: "&#x1F469;&#x200D;&#x1F4BB;",
    text: "Passo a passo",
  },
  {
    text: "Benefits",
  },
  {
    text: "Process",
  },
]

export const BadgesTestBenefits = [
  {
    icon: "&#x1F64C;",
    text: "Vantagens",
  },
  {
    text: "Quality",
  },
  {
    text: "Experience",
  },
]

export const badgesBigNumbers = [
  {
    icon: "&#x1F4A1;",
    text: "Referência",
  },
  {
    text: "Clients",
  },
  {
    text: "Projects",
  },
]

export const badgesPlans = [
  {
    icon: "&#x1F576;",
    text: "Planos",
  },
  {
    text: "Start",
  },
  {
    text: "Test",
  },
]

// INFO

export const infoReferences = {
  title: "Fazendo a diferença em projetos no Brasil inteiro",
  textButton: "Agende uma reunião",
}
export const infoFooter = {
  address: "Av. Marcos de Freitas Costa, 737 | Uberlândia-MG ",
  linkAddres:
    "https://www.google.com/maps/place/Av.+Marcos+de+Freitas+Costa,+737+-+Daniel+Fonseca,+Uberl%C3%A2ndia+-+MG,+38400-328/@-18.9194983,-48.2987765,17z/data=!3m1!4b1!4m5!3m4!1s0x94a4445c354a4e8f:0x3a80226135af14f5!8m2!3d-18.9195034!4d-48.2965878",
  number: "+55(34)3229-5811",
  email: "contato@enesolucoes.com.br",
  textRedirect: "Link para página LGPD",
  redirect: "/dpc",
  logo: logoWhite,
  copyright: "Todos os direitos reservados © 2022 - eNe Soluções",
}
export const infoSquadAllocation = {
  title: "O que fazemos",
  textButton: "Entre em contato",
}

export const designStepByStepInfo = {
  title: "Entenda como funciona",
  textButton: "Quero contratar",
}
export const infoTestBenefits = {
  title: "Veja como podemos <span>te ajudar</span>:",
  textButton: "Quero contratar",
  textSmallButton: "Quero saber mais",
}

export const infoBigNumbers = {
  title:
    "Por que é uma <span>boa ideia escolher a eNe</span> pra construir seu projeto?",
  description:
    "Desenhamos soluções sob medida para algumas das maiores marcas do Brasil, contribuindo para que diversos tipos de objetivo fossem alcançados.",
  numbers1: "500",
  text1: "projetos entregues",
  numbers2: "1500",
  text2: "telas criadas sob medida",
}

export const infoBigNumbersQa = {
  title:
    "Por que é uma <span>boa ideia escolher a eNe</span> pra construir seu projeto?",
  description:
    "Desenhamos soluções sob medida para algumas das maiores marcas do Brasil, contribuindo para que diversos tipos de objetivo fossem alcançados.",
  numbers1: "500",
  text1: "projetos entregues",
  numbers2: "95%",
  text2: "redução de retrabalho",
}

export const designRecommendationInfo = {
  title:
    "Ainda em dúvida? Veja o que os <span>nossos clientes</span> têm a dizer:",
  companylogo: companyLogoDesktop,
  companylogoMobile: companyLogoMobile,
  companylogoTablet: companyLogoTablet,
  altCompanyLogo: "Logo clientes",
}
export const infoRecommendationQA = {
  title:
    "Ainda em dúvida? Veja o que os <span>nossos clientes</span> têm a dizer:",
  companylogo: companyLogoDesktop,
  companylogoMobile: companyLogoMobile,
  companylogoTablet: companyLogoTablet,
  altCompanyLogo: "Logo de clientes",
}

export const infoPlans = {
  titleQA: "Vamos <span>começar</span>?",
  titleDesign: "<span>Vamos começar?</span>",
  subtitleDesign: "Escolha seu plano!",
}

//CARDS

export const infoCtaContract = {
  title: "Gostou? Então <span>vamos dar início</span> ao seu projeto!",
  textButton: "Quero contratar",
}

export const cardsSquadAllocation = [
  {
    icon: squadIconAsService,
    iconAlt: "squadIconAsService",
    title: "Squad as a service",
    description:
      "Alocamos times tech completos na medida da sua operação, que atuam em diversas stacks: front e back-end, UX/UI, Quality Assurance (QA), DevOps, Team Lead, Tech Lead, Salesforce e Data Analytics.",
  },

  {
    icon: squadIconProjectMeasure,
    iconAlt: "squadIconProjectMeasure",
    title: "Projetos sob medida",
    description:
      "Criamos e desenvolvemos o seu projeto digital, incluindo apps, plataformas web e mobile, protótipos, e-commerces e muito mais. Atendemos às necessidades do seu negócio com as tecnologias mais avançadas.",
  },

  {
    icon: squadIconMVP,
    iconAlt: "squadIconMVP",
    title: "MVP",
    description:
      "Tenha uma versão funcional da sua ideia em poucos dias. Nossa expertise em tecnologia, processos e mercado garante uma solução MVP superior, seja um app, plataforma web ou dashboard.",
  },

  {
    icon: squadIconImmersion,
    iconAlt: "squadIconImmersion",
    title: "Imersão",
    description:
      "Após um mapeamento completo da sua operação, você receberá um roadmap detalhado e um plano de ação claro, que facilitará a implementação de novas tecnologias e abrirá portas para novas oportunidades.",
  },
]

export const designStepByStepCards = [
  {
    icon: designStepByStepSchedule,
    altIcon: "Imagem de calendário",
    title: "Contrate e agende seu horário",
    description:
      "Escolha um dos horários disponíveis para conversar com a gente. Afinal, sua presença é essencial em cada consultoria. (Horários disponíveis Segunda a sexta: 09h às 18h)",
  },
  {
    icon: designStepByStepFile,
    altIcon: "Imagem de arquivo",
    title: "Compartilhe informações do projeto",
    description:
      "Envie tudo o que o seu projeto já possui de comunicação visual, como logotipo ou manual de marca. Caso você não tenha, nós desenvolvemos por aqui.",
  },
  {
    icon: designStepByStepTalk,
    altIcon: "Imagem balão de fala",
    title: "Converse com o time de especialistas",
    description:
      "Construa junto com um time de especialistas o esboço e o protótipo navegável do seu projeto e altere quantas vezes quiser, durante a consultoria.",
  },
]

export const cardsTestBenefits = [
  {
    id: 0,
    icon: imgTesteResponsivo,
    altIcon: "Teste responsivo",
    title: "Teste responsivo",
    description:
      "Veja como seu site ou app se adapta em diferentes resoluções e dispositivos.",
  },
  {
    id: 1,
    icon: imgTesteAcessibilidade,
    altIcon: "Teste de acessibilidade",
    title: "Teste de acessibilidade",
    description:
      "Visa garantir a qualidade da aplicação para pessoas que enfrentam algum tipo de deficiência.",
  },
  {
    id: 2,
    icon: imgTesteUsabilidade,
    altIcon: "Teste de usabilidade",
    title: "Teste de usabilidade",
    description:
      "Focado  na facilidade de navegação entre as telas, a clareza de textos e mensagens exibidas ao usuário",
  },
  {
    id: 3,
    icon: imgTesteFuncionalidade,
    altIcon: "Teste de funcionalidade",
    title: "Teste de funcionalidade",
    description:
      "Simula todos cenários de negócios e garante que todos os requisitos funcionais estejam implementados.",
  },
  {
    id: 4,
    icon: imgTesteAutomatizado,
    altIcon: "Teste automatizado",
    title: "Teste automatizado",
    description:
      "Buscam simplificar o esforço de teste com um conjunto de scripts. Podem ser executados repetidamente a qualquer hora do dia.",
  },
]
export const designRecommendationCards = [
  {
    image: recommendationImgCard,
    altImg: "Picture",
    title: "Cliente",
    subtitle: "Projetos desenvolvidos: Web, App, Sistemas Internos",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce faucibus metus ac urna tempus, sit amet rutrum est vestibulum. Nullam tempor ipsum et quam condimentum, a sodales ex eleifend. In dictum enim in tortor tincidunt consequat.",
  },
  {
    image: recommendationImgCard,
    altImg: "Picture",
    title: "Cliente",
    subtitle: "Projetos desenvolvidos: Web, App, Sistemas Internos",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce faucibus metus ac urna tempus, sit amet rutrum est vestibulum. Nullam tempor ipsum et quam condimentum, a sodales ex eleifend. In dictum enim in tortor tincidunt consequat.",
  },
  {
    image: recommendationImgCard,
    altImg: "Picture",
    title: "Cliente",
    subtitle: "Projetos desenvolvidos: Web, App, Sistemas Internos",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce faucibus metus ac urna tempus, sit amet rutrum est vestibulum. Nullam tempor ipsum et quam condimentum, a sodales ex eleifend. In dictum enim in tortor tincidunt consequat.",
  },
]
export const cardsRecommendationQA = [
  {
    image: recommendationImgCard,
    altImg: "Picture",
    title: "Cliente",
    subtitle: "Projetos desenvolvidos: Web, App, Sistemas Internos",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce faucibus metus ac urna tempus, sit amet rutrum est vestibulum. Nullam tempor ipsum et quam condimentum, a sodales ex eleifend. In dictum enim in tortor tincidunt consequat.",
  },
  {
    image: recommendationImgCard,
    altImg: "Picture",
    title: "Cliente",
    subtitle: "Projetos desenvolvidos: Web, App, Sistemas Internos",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce faucibus metus ac urna tempus, sit amet rutrum est vestibulum. Nullam tempor ipsum et quam condimentum, a sodales ex eleifend. In dictum enim in tortor tincidunt consequat.",
  },
  {
    image: recommendationImgCard,
    altImg: "Picture",
    title: "Cliente",
    subtitle: "Projetos desenvolvidos: Web, App, Sistemas Internos",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce faucibus metus ac urna tempus, sit amet rutrum est vestibulum. Nullam tempor ipsum et quam condimentum, a sodales ex eleifend. In dictum enim in tortor tincidunt consequat.",
  },
]

export const cardsPlansDesign = [
  {
    title: "Básico",
    portion: "Em até 12x de",
    portionAmount: "R$ <span>388</span> <span>,45</span>",
    amount: "ou R$ <span> 3.987,00</span> à vista",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce faucibus metus ac urna tempus.",
    benefits: [
      "Até 6 telas",
      "Protótipo Navegável",
      "Styleguide básica do Projeto (App ou WebApp)",
    ],
    textButton: "Contratar",
  },
  {
    title: "Premium",
    portion: "Em até 12x de",
    portionAmount: "R$ <span>777</span> <span>,18</span>",
    amount: "ou R$ <span> 7.977,00</span> à vista",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce faucibus metus ac urna tempus.",
    benefits: [
      "Até 10 telas",
      "Protótipo Navegável",
      "Styleguide básica do Projeto (App ou WebApp)",
      "Responsividade",
    ],
    textButton: "Contratar",
  },
]
export const cardsPlansQA = [
  {
    title: "Teste de Usabilidade",
    portion: "Em até 10x de",
    portionAmount: "R$ <span>219</span> <span>,90</span>",
    amount: "ou R$ <strong> 2.199,00</strong> à vista",
    description:
      "Teste das navegações entre telas, clareza dos textos, garantir facilidade ao usuário em utilizar a aplicação.",
    benefits: [
      "Até 6 telas",
      "Protótipo Navegável",
      "Styleguide básica do Projeto (App ou WebApp)",
    ],
    textButton: "Contratar",
  },
  {
    title: "Teste Responsivo",
    portion: "Em até 10x de",
    portionAmount: "R$ <span>219</span> <span>,90</span>",
    amount: "ou R$ <strong> 2.199,00</strong> à vista",
    description:
      "Garanta adaptação completa nas resoluções independente do tamanho da tela.",
    benefits: [
      "Até 6 telas",
      "Protótipo Navegável",
      "Styleguide básica do Projeto (App ou WebApp)",
    ],
    textButton: "Contratar",
  },
  {
    title: "Teste de Acessibilidade",
    portion: "Em até 10x de",
    portionAmount: "R$ <span>219</span> <span>,90</span>",
    amount: "ou R$ <strong> 2.199,00</strong> à vista",
    description:
      "Permite usabilidade a pessoas que enfrentam algum tipo de restrição.",
    benefits: [
      "Até 6 telas",
      "Protótipo Navegável",
      "Styleguide básica do Projeto (App ou WebApp)",
    ],
    textButton: "Contratar",
  },
]

export const dataForm = {
  title: "Vamos construir <span>projetos incríveis</span> juntos?",
  reason: "Qual é o motivo do contato?",
  reasonOptions: [
    {
      id: 0,
      text: "Tenho um projeto (Back-End, Front-End ou Aplicativo), estou em busca de uma empresa para desenvolver",
    },
    {
      id: 1,
      text: "Preciso contratar um profissional de TI",
    },
    {
      id: 2,
      text: "Preciso de uma consultoria em Soluções Digitais",
    },
    {
      id: 3,
      text: "Quero obter mais informações sobre a eNe Soluções",
    },
    {
      id: 4,
      text: "Ofertar um produto ou serviço",
    },
  ],
  moment: "Qual é o momento?",
  momentOptions: [
    {
      id: 0,
      text: "Sou uma pessoa física, tive uma ideia",
    },
    {
      id: 1,
      text: "Sou um profissional de uma pequena empresa",
    },
    {
      id: 2,
      text: "Sou um profissional de uma média empresa",
    },
    {
      id: 3,
      text: "Sou um profissional de uma grande empresa",
    },
    {
      id: 4,
      text: "Sou um profissional que trabalha para o governo",
    },
  ],
  contact: "Em qual canal você gostaria de receber nosso retorno?",
  contactOptions: [
    {
      id: 0,
      text: "E-mail",
    },
    {
      id: 1,
      text: "WhatsApp",
    },
    {
      id: 2,
      text: "Ligação",
    }
  ],
  
  name: "Digite seu nome",
  job: "Cargo",
  company: "Empresa",
  email: "Seu e-mail",
  phone: "Telefone/whatsapp",
  textButton: "Enviar",
  invalidEmail: "Insira um e-mail válido.",
  invalidPhone: "Insira um número válido.",
  invalidRecaptcha: "Prove que você não é um robô.",
  requiredField: "O campo é obrigatório.",
  submitMessageSucess: "Formulário enviado com sucesso!",
  submitMessageError: "Erro ao enviar o formulário!",
  solutionInterest: "Solução de interesse",
}
export const infoItemsMenu = {
  menuHome: [
    {
      label: "Serviços",
      button: false,
      url: "servicos",
      type: "redirectScroll",
    },
    {
      label: "Cases",
      button: false,
      url: "cases",
      type: "redirect",
    },
    {
      label: "Blog",
      button: false,
      url: "blog",
      type: "redirect",
    },
    {
      label: "Contato",
      button: false,
      url: "contato",
      type: "redirectScroll",
    },
    {
      label: "Vagas",
      button: false,
      url: "https://jobs.quickin.io/enesolucoes/jobs",
      type: "redirect",
    },
    {
      label: "Contratar",
      button: true,
      url: "contato",
      type: "redirectScroll",
    },
  ],
  "qualidade-de-software": [
    {
      label: "Tipos de testes",
      button: false,
      url: "test-benefits",
      type: "scroll",
    },
    {
      label: "Benefícios",
      button: false,
      url: "timeline",
      type: "scroll",
    },
    {
      label: "Planos",
      button: false,
      url: "plans",
      type: "scroll",
    },
    {
      label: "Por que escolher a eNe",
      button: false,
      url: "big-numbers",
      type: "scroll",
    },
    {
      label: "Contratar",
      button: true,
      url: "plans",
      type: "scroll",
    },
  ],
  "design-de-solucoes": [
    {
      label: "Como funciona",
      button: false,
      url: "step-by-step",
      type: "scroll",
    },
    {
      label: "Benefícios",
      button: false,
      url: "timeline",
      type: "scroll",
    },
    {
      label: "Planos",
      button: false,
      url: "plans",
      type: "scroll",
    },
    {
      label: "Por que escolher a eNe",
      button: false,
      url: "big-numbers",
      type: "scroll",
    },
    {
      label: "Contratar",
      button: true,
      url: "plans",
      type: "scroll",
    },
  ],
  "politicas-de-privacidade": [
    {
      label: "Serviços",
      button: false,
      url: "servicos",
      type: "redirectScroll",
    },
    {
      label: "Contato",
      button: false,
      url: "contato",
      type: "redirectScroll",
    },
    {
      label: "Vagas",
      button: false,
      url: "https://jobs.quickin.io/enesolucoes/jobs",
      type: "redirect",
    },
    {
      label: "Contratar",
      button: true,
      url: "contato",
      type: "redirectScroll",
    },
  ],
  "termos-de-uso": [
    {
      label: "Serviços",
      button: false,
      url: "servicos",
      type: "redirectScroll",
    },
    {
      label: "Contato",
      button: false,
      url: "contato",
      type: "redirectScroll",
    },
    {
      label: "Vagas",
      button: false,
      url: "https://jobs.quickin.io/enesolucoes/jobs",
      type: "redirect",
    },
    {
      label: "Contratar",
      button: true,
      url: "contato",
      type: "redirectScroll",
    },
  ],
  dpc: [
    {
      label: "Serviços",
      button: false,
      url: "servicos",
      type: "redirectScroll",
    },
    {
      label: "Contato",
      button: false,
      url: "contato",
      type: "redirectScroll",
    },
    {
      label: "Vagas",
      button: false,
      url: "https://jobs.quickin.io/enesolucoes/jobs",
      type: "redirect",
    },
    {
      label: "Contratar",
      button: true,
      url: "contato",
      type: "redirectScroll",
    },
  ],
  "ene-academy": [
    {
      label: "O Programa",
      button: false,
      url: "program",
      type: "scroll",
    },
    {
      label: "Para quem",
      button: false,
      url: "for-who",
      type: "scroll",
    },
    {
      label: "Como funciona",
      button: false,
      url: "step-by-step",
      type: "scroll",
    },
    {
      label: "Inscreva-se ",
      button: true,
      url: "contato",
      type: "scroll",
    },
  ],
}

export const infoHeroSolutionDigitals = {
  rowReverse: false,
  linearGradient: false,
  backgroundColor1: "",
  backgroundColor2: "",
  title:
    "Somos o braço direito de <span>tecnologia e inovação</span> que a sua empresa precisa",
  description:
    "A eNe é uma empresa de tecnologia fundada em 2011, especialista em alocar os profissionais certos para desenvolver as soluções digitais, que vão escalar e destacar o seu negócio no mercado",
  subtitle: "",
  media: {
    image: "imgDigitalSolitions", // se image estiver com conteudo "" reinderiza video
    image_description: "Soluções Digitais",
    video_url: "",
  },
  badge: [
    {
      icon: "&#x1F469;&#x200D;&#x1F4BB;",
      text: "Dev",
    },
    {
      icon: "",
      text: "Design",
    },
    {
      text: "Consult",
      icon: "",
    },
  ],
  button: [
    {
      children: "Quero saber mais",
      preset: "solidCyan",
      type: "submit",
      onClick: () => {
        navigate("/#contato")
      },
    },
  ],
}

export const infoHeroTechnologies = {
  rowReverse: false,
  linearGradient: false,
  backgroundColor1: "",
  backgroundColor2: "",
  title: "Tech de ponta <br/> &lt;<span>end-to-end</span>&gt;",
  description:
    "Nós respiramos tecnologia! Sempre alinhados com as tendências em tech, utilizamos as técnicas e ferramentas mais avançadas. <br><br>Nosso time de desenvolvimento trabalha visando entregar aplicações de tecnologias com alta qualidade técnica, de ponta a ponta.",
  subtitle: "",
  media: {
    image: "imgTechnologies", // se image estiver com conteudo "" reinderiza video
    image_description: "Tecnologias",
    video_url: "",
  },
  badge: [
    {
      icon: "&#x1F469;&#x200D;&#x1F4BB;",
      text: "Tecnologias",
    },
    {
      text: "Coding",
    },
    {
      text: "Process",
    },
  ],
  button: [
    {
      children: "Fale com nossos especialistas",
      preset: "solidCyan",
      type: "button",
      onClick: () => {
        navigate("/#contato")
      },
    },
  ],
}

export const designCarouselProjectsInfo = {
  rowReverse: false,
  linearGradient: false,
  backgroundColor1: "#071926",
  backgroundColor2: "",
  title: "Você pensa, <span>a gente transforma:</span>",
  subtitle: [
    "<span>”</span>Quero criar uma página para vender meu app de vistoria.<span>”</span>",
    "<span>”</span>Quero fortalecer o branding da minha marca e garantir uma boa experiência pro usuário de forma criativa e inovadora.<span>”</span>",
    "<span>”</span>Quero criar um site moderno, elegante e luxuoso que associe meus produtos à experiência de viver bem.<span>”</span>",
    "<span>”</span>Preciso redesenhar toda a experiência do usuário e atualizar as tecnologias e design que já estão defasados há anos.<span>”</span>",
  ],
  media: [
    {
      image: "carouselSuaVistoria",
      image_description: "Autoscar",
      video_url: "",
    },
    {
      image: "carouselBiogenetics", // se image estiver com conteudo "" reinderiza video
      image_description: "DNA Secreto",
      video_url: "",
    },
    {
      image: "carouselAvecFolie",
      image_description: "Avec Folie",
      video_url: "",
    },
    {
      image: "carouselAutoscar",
      image_description: "Autoscar",
      video_url: "",
    },
  ],
  badge: [
    {
      icon: "&#x1F4D0;",
      text: "Projetos",
    },
    {
      text: "Think",
      icon: "",
    },
    {
      text: "Design",
      icon: "",
    },
  ],
  button: [
    {
      children: "Quero contratar",
      preset: "outlineCyan",
      type: "submit",
      onClick: () => {
        navigate("/design-de-solucoes/#plans")
      },
    },
  ],
}
export const designHeroInfo = {
  rowReverse: false,
  linearGradient: false,
  backgroundColor1: "#071926",
  backgroundColor2: "",
  title: "Já pensou em <span>tirar sua ideia do papel?</span>",
  description:
    "A gente te ajuda a mudar pro digital. E o melhor: do seu jeito!",
  subtitle: "",
  media: {
    image: "designHeroBackground", // se image estiver com conteudo "" reinderiza video
    image_description: "Design",
    video_url: "",
  },
  badge: [
    {
      icon: "&#x270F;&#xFE0F;",
      text: "Criação",
    },
    {
      text: "Build",
    },
    {
      text: "Experience",
    },
  ],
  button: [
    {
      children: "Quero conhecer",
      preset: "solidCyan",
      type: "submit",
      onClick: () => {
        navigate("/design-de-solucoes/#step-by-step")
      },
    },
    {
      children: "Planos",
      preset: "outlineCyan",
      type: "submit",
      onClick: () => {
        navigate("/design-de-solucoes/#plans")
      },
    },
  ],
}

export const designTimelineInfo = [
  {
    id: "0",
    title: "Consultoria com especialistas",
    description: `Para entender exatamente o que o seu projeto precisa, 
    oferecemos uma consultoria 100% online de até 2 horas com uma equipe 
    preparada para colocar sua ideia em prática. Tudo exatamente da forma 
    que você pensou!`,
    image: designExpertConsultation,
  },
  {
    id: "1",
    title: "Wireframe da sua solução",
    description: `O Wireframe é um protótipo que permite enxergar como o 
    produto final da sua ideia vai ficar. Durante o período da consultoria, 
    podemos ajustar o esboço quantas vezes for necessário.`,
    image: designWireframe,
  },
  {
    id: "2",
    title: "Conceito Visual",
    description: `É nessa etapa que sua ideia começa a tomar forma. 
    Estruturamos a linha criativa dando vida a sites, apps, produtos 
    ou o que a sua imaginação mandar.`,
    image: designVisualConcept,
  },
  {
    id: "3",
    title: "Protótipo navegável",
    description: `Navegue entre as páginas, teste cada botão, simule fluxos. 
    É exatamente assim que seu site ou app vai funcionar quando estiver 
    online.`,
    image: designSailablePrototype,
  },
  {
    id: "4",
    title: "Projeção para o desenvolvimento",
    description: `Saiba quanto tempo é necessário para que possamos 
    desenvolver seu projeto.`,
    image: designProjectionForTheDevelopment,
  },
]

export const infoTimelineQA = [
  {
    id: "0",
    image: qaTimelineSpecialistConsultation,
    title: "Consultoria com especialistas",
    description: `Para entender exatamente o que o seu projeto precisa, 
    oferecemos uma consultoria 100% online de até 2 horas com uma equipe 
    preparada para colocar sua ideia em prática, com alta qualidade e 
    minimização de erros. `,
  },
  {
    id: "1",
    image: qaTimelineMacroRoadmap,
    title: "Elaboração do roteiro de macro",
    description: `Juntos, vamos elaborar o plano de testes do seu sistema, 
    software ou produto e descrever as premissas necessárias para a execução 
    de cada requisito do projeto.`,
  },
  {
    id: "2",
    image: qaTimelineTest,
    title: "Realização do teste",
    description: `Os testes são realizados de acordo com o escopo elaborado 
    no roteiro, garantindo que toda a atividade definida seja testada seguindo 
    as melhores práticas da engenharia de software.`,
  },
  {
    id: "3",
    image: qaTimelineProblemsEncountered,
    title: "Relatório de todos os problemas encontrados",
    description: `Após executar os testes, disponibilizamos um relatório 
    detalhado com as falhas e bugs encontrados no projeto.`,
  },
  {
    id: "4",
    image: qaTimelineApresentation,
    title: "Apresentação",
    description: `Em uma nova reunião com o time de especialistas, 
    você verá a apresentação dos testes executados, além de um overview 
    de cada etapa.`,
  },
  {
    id: "5",
    image: qaTimelineRetest,
    title: "Re-teste",
    description: `Nesse cenário, os engenheiros de teste executam os 
    casos novamente para verificar o status dos erros. Em caso de erros 
    não resolvidos, enviamos outro relatório de erros para depuração 
    adicional.`,
  },
]

export const designTimelineInfoDefault = {
  title: "O que está <span>incluso</span>",
  background: "bg-elephant",
  button: [
    {
      children: "Quero agora",
      preset: "solidCyan",
      type: "submit",
      onClick: () => {
        navigate("/design-de-solucoes/#plans")
      },
    },
  ],
}

export const qaTimelineInfoDefault = {
  title: "O que está <span>incluso</span>",
  background: "bg-blackPearl",
  button: [
    {
      children: "Quero contratar",
      preset: "outlineCyan",
      type: "submit",
      onClick: () => {
        navigate("/qualidade-de-software/#plans")
      },
    },
  ],
}

export const infoQaHeroQuality = {
  rowReverse: false,
  lienarGradient: false,
  backgroundColor1: "",
  backgroundColor2: "",
  title:
    "Quer <span>extrair todo o potencial</span> do seu site ou aplicativo?",
  subtitle: "Conte com a nossa análise de qualidade para tornar isso possível!",
  description:
    "Sabia que em média 80% dos consumidores deixaram de consumir qualquer produto ou serviço da sua empresa após uma experiência ruim? <br/>A análise de qualidade blinda seu projeto desse problema, garantindo que os clientes recebam um site ou aplicativo que atendam suas expectativas. <br/>Fonte: <i>Fifth Quadrant</i>",
  media: {
    image: "qaHeroQuality",
    image_description: "",
    video_url: "",
  },
  badge: [
    {
      icon: "&#x1F3C6",
      text: "Qualidade",
    },
    {
      text: "UX",
    },
    {
      text: "ACCESSIBILITY",
    },
  ],
  button: [
    {
      children: "Quero conhecer",
      preset: "solidCyan",
      type: "submit",
      onClick: () => {
        navigate("/qualidade-de-software/#test-benefits")
      },
    },
  ],
}

export const infoQaHeroSecurity = {
  rowReverse: false,
  lienarGradient: false,
  backgroundColor1: "",
  backgroundColor2: "",
  title: "<span>Redução</span> de risco e custo de manutenção de software",
  subtitle: "",
  description:
    "Quanto mais tempo um bug permanece em um programa, a probabilidade de ter código adicional escrito em torno dele é maior. Isso exigiria mais testes, o que geraria um custo adicional. A detecção precoce de bugs minimiza o tempo de ajustes e evita retrabalho, garantindo que o projeto seja entregue no prazo. Em um mundo repleto de ameaças digitais, proteger a exposição de dados e informações confidenciais é essencial.",
  media: {
    image: "qaHeroSecurity",
    image_description: "",
    video_url: "",
  },
  badge: [
    {
      icon: "&#x1F512",
      text: "Segurança",
    },
    {
      text: "ANALYZE",
    },
    {
      text: "SUPPORT",
    },
  ],
}

export const infoEneAcademyHeroDevelop = {
  rowReverse: false,
  linearGradient: false,
  backgroundColor1: "",
  backgroundColor2: "",
  title:
    "<span>eNe Academy</span><br>Dê um <span>power up no seu conhecimento</span>",
  description: `Tá na faculdade ou acabou de sair e quer acelerar seu conhecimento? Chegou ao lugar certo. 
  A eNe Academy é um <b>programa gratuito</b> focado em levar suas skills como desenvolvedor para o próximo nível.
  Aprenda de forma colaborativa, encare desafios dinâmicos e tenha a chance de fazer parte do nosso time.`,
  subtitle: "<b>Fala Dev O/</b>",
  media: {
    image: "eneAcademyHeroDevelop", // se image estiver com conteudo "" reinderiza video
    image_description: "Monalisa",
    video_url: "",
  },
  badge: [
    {
      icon: "&#x1F4BB;",
      text: "DEV",
    },
    {
      icon: "",
      text: "Learning",
    },
    {
      text: "LVL UP",
      icon: "",
    },
  ],
  button: [
    {
      children: "Vem participar",
      preset: "solidCyan",
      type: "submit",
      onClick: () => {
        navigate("/ene-academy/#form")
      },
    },
  ],
}

export const infoEneAcademyHeroProfile = {
  rowReverse: true,
  linearGradient: false,
  backgroundColor1: "",
  backgroundColor2: "",
  title: "<span>Para quem</span> é a eNe Academy?",
  description:
    "A eNe Academy foi criada para alunos no curso de sistemas e devs recém-formados que buscam emprego na área ou querem evoluir suas habilidades com o stack Front-End, utilizando o React, Gatsby e, TypeScript em seus projetos. Procuramos pessoas apaixonadas por tecnologia, de qualquer lugar do Brasil, que tem sede de conhecimento e querem aprender na prática o que de fato é utilizado no mercado de trabalho.",
  subtitle: "",
  media: {
    image: "eneAcademyHeroProfile", // se image estiver com conteudo "" reinderiza video
    image_description: "Monalisa",
    video_url: "",
  },
  badge: [
    {
      icon: "&#x1F466;",
      text: "Perfil",
    },
    {
      icon: "",
      text: "Develop",
    },
    {
      text: "Evolution",
      icon: "",
    },
  ],
  button: [
    {
      children: "Vem participar",
      preset: "solidCyan",
      type: "submit",
      onClick: () => {
        navigate("/ene-academy/#form")
      },
    },
  ],
}

export const infoTimelineEneAcademy = [
  {
    image: eneAcademyTimeline,
    title: "Inscrições",
    description: `Após realizar sua inscrição, você receberá via e-mail todas as 
    informações que precisa para participar do curso. Fique atento às datas para 
    não perder nenhuma aula.`,
  },
  {
    image: eneAcademyTimeline,
    title: "Pré-requisitos",
    description: `Para participar das aulas e conseguir absorver o que está sendo 
    ensinado é necessário conhecimento básico de CSS, HTML e Javascript.`,
  },
  {
    image: eneAcademyTimeline,
    title: "Aulas práticas",
    description: `Você irá participar de 05 aulas práticas realizadas de xx a xx de agosto, 
    via Youtube. No final de cada uma, enviaremos um desafio referente ao conteúdo aplicado. 
    Após cumprir essa etapa, basta submeter o material desenvolvido para validação.`,
  },
  {
    image: eneAcademyTimeline,
    title: "Principais aprendizados",
    description: `O objetivo da eNe Academy é formar desenvolvedores Front-End com as tecnologias
     usadas no mercado como React, Gatsby e TypeScript. Aprenda conceitos práticos do mercado e 
     ganhe mais confiança e autonomia ao desenvolver novos projetos com uma metodologia de ensino 
     focada em necessidades reais.`,
  },
  {
    image: eneAcademyTimeline,
    title: "Oportunidade de contratação",
    description: `Ao longo do curso, cada desenvolvedor será analisado individualmente e os 10 
    que obtiverem melhor desempenho irão participar de um processo seletivo com possibilidade de 
    contratação imediata na eNe Soluções.
    `,
  },
]

export const eneAcademyTimelineBadges = [
  {
    icon: "&#x1F4A1;",
    text: "Passo a passo",
  },
  {
    text: "Benefits",
  },
  {
    text: "Knowledge",
  },
]

export const eneAcademyTimelineInfoDefault = {
  title: "Como funciona:",
  background: "bg-blackPearl",
  button: [
    {
      children: "Faça parte da eNe Academy",
      preset: "solidCyan",
      type: "submit",
      onClick: () => {
        navigate("/ene-academy/#form")
      },
    },
  ],
}

export const eneAcademyFormBadges = [
  {
    icon: "&#x1F3C1;",
    text: "Começar",
  },
  {
    text: "Start",
  },
  {
    text: "Now",
  },
]

export const eneAcademyIconContent = {
  title: "O Programa",
  badges: [
    {
      icon: "&#x1F522;",
      text: "Fórmula",
    },
    {
      text: "Fast",
    },
    {
      text: "Free",
    },
  ],
  content: [
    {
      image: "eneAcademyIconContentComputer",
      text: "Aprenda desenvolvendo",
      alternativeText: "Aprenda desenvolvendo",
    },
    {
      image: "eneAcademyIconContentPlay",
      text: "05 aulas gratuitas",
      alternativeText: "Aulas gratuitas",
    },
    {
      image: "eneAcademyIconContentSaveTheDate",
      text: "De xx a xx de agosto",
      alternativeText: "Data de início",
    },
    {
      image: "eneAcademyIconContentContract",
      text: "Chance de contratação",
      alternativeText: "Contratação",
    },
  ],
  button: {
    label: "Cadastrar agora",
    preset: "outlineCyan",
    type: "button",
    onClick: () => {
      navigate("/ene-academy/#form")
    },
  },
}
